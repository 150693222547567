@import "mixin";

$maincolor: #cf002c;
$color05: #898989;

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  overflow-y: scroll;
  color: rgba(0, 0, 0, 0.7);
  background-color: #f8f8f8;
}

a{
  &:hover,&:focus{
    text-decoration: none;
    outline: none;
  }
}

img{
  max-width: 100%;
}
.menu{
  background-color: rgba(#FFF, 0.95);
  position: fixed;
  top: 20px;
  width: 100%;
  z-index: 100;
  background-image: url(../img/moroz.png);
  padding-right: 80px;

  &__snow{
    background-image: url(../img/snow.png);
    background-repeat: repeat-x;
    background-position-y: 1px;
    height: 39px;
    width: 100%;
    position: absolute;
    top: -12px;
    left: 0;
    z-index: 3;
  }
  &__link{
    display: inline-block;
    padding: 30px 20px 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.8px;
    color: #222;
  }
}
.topSlider{
  position: relative;
  :after{
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    background-image: url(../img/moroz.png);
    background-size: cover;
    opacity: 0.2;
  }
  &__wrap{
    position: relative;
  }
  &__textWrap{
    bottom: 30px;
    //background: rgba(#002051, 0.8);
    position: absolute;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    img{
      width: 32px;
      margin: 10px 20px;
    }
  }
  &__text{
    background: rgba(#FFF, 0.6);
    padding: 50px;
    color: #222;
    font-size: 24px;
    font-family: 'PT Sans Caption', sans-serif;
    h1{
      font-size: 48px;
    }
  }
  &__separator{
     width: 70%;
    border-bottom: 1px solid #000;
    margin: 40px auto;
  }
  &__phone{
    font-family: Roboto;
    font-size: 28px;
    a{
      color: #000;
      &:hover, &:focus{
        color: #000;
        text-decoration: none;
      }
    }
  }
  &__item{
    height: 850px;
    background-size: cover;
    background-position: center;
    &--item1{
      background-image: url(../img/7.jpg);
    }
    &--item2{
      background-image: url(../img/4.jpg);
      background-position: center left;
    }
    &--item3{
      background-image: url(../img/44.jpg);
    }
  }
}

.description{
  font-size: 24px;
  background-color: #FFF;
  color: #000;
  padding: 100px 0;
  .container{
  }
}

.program{
  padding: 160px 0;
  background-image: url(../img/33.jpg);
  background-size: cover;
  background-position: center;
  &__text{
    padding: 50px;
    background-color: rgba(#fff, 0.9);
    font-size: 18px;
    color: #000;
  }
  &__title{
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
  }
}

.gallery{
  padding: 100px 0;
  color: #000;
  &__title{
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
  }
  &__item{
    height: 260px;
    background-position: center;
    background-size: cover;
    display: block;
    border: 5px solid #FFF;
    &__wrap{
      margin-bottom: 30px;
    }
  }
}

.price{
  padding: 160px 0;
  background-image: url(../img/5.jpg);
  background-size: cover;
  background-position: center;
  &__text{
    padding: 50px;
    background-color: rgba(#fff, 0.9);
    font-size: 16px;
    color: #000;
  }
  &__title{
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
  }
  &__hint{
    font-size: 12px;
    text-align: center;
  }
}

.contacts{
  padding: 100px 0;
  color: #222;
  &__img{
    width: 28px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  &__item{
    margin-bottom: 30px;
  }
  &__title{
    font-size: 24px;
  }
  &__value{
    font-size: 42px;
    font-weight: 500;
    &--email{
      font-size: 32px;
    }
    &--instagram{
      font-size: 36px;
    }
    a{
      color: #000;
      &:hover, &:focus{
        color: #000;
        text-decoration: none;
      }
    }
  }
}


@media (max-width: 768px) {
  .menu{
    padding-right: 0;
    position: absolute;
    top: 10px;
    padding-top: 10px;
    &__link{
      font-size: 10px;
      padding: 15px 20px;
    }
  }
  .topSlider__item{
    height: 700px;
    &--item2{
      background-position: center center;
    }
  }
  .topSlider__textWrap{
    width: 95%;
  }
  .topSlider__text{
    padding: 50px 20px;
    h1{
      font-size: 30px;
    }
  }
  .topSlider__phone{
    font-size: 18px;
    margin-bottom: 10px;
  }
  .description {
    font-size: 16px;
    padding: 30px 0;
  }
  .program {
    padding: 250px 0 0;
  }
  .program__text {
    padding: 20px 10px;
    font-size: 16px;
    margin: 0 -15px;
  }
  .gallery{
    padding: 30px 0;
  }
  .gallery__item {
    height: 160px;
  }
  .price {
    padding: 250px 0 0;
    background-position: bottom;
  }
  .price__text {
    padding: 20px 10px;
    font-size: 16px;
    margin: 0 -15px;
    td:first-child{
      width: 70%;
    }
  }
  .contacts{
    text-align: center;
  }
  .contacts__value{
    font-size: 28px;
    &--email{
      font-size: 22px;
    }
    &--instagram{
      font-size: 22px;
    }
  }
  .contacts__title {
    font-size: 16px;
    max-width: 70%;
    margin: 0 auto;
  }
  .contacts__img{
    margin: 0 10px 20px;
  }
}
